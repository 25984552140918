import { createApp } from 'vue'
import App from './App.vue'
import './style.css'
import HelloWorld from "@/components/HelloWorld.vue";
import ThankYou from "@/components/ThankYou.vue";
import {createRouter, createWebHistory} from "vue-router";
import AdditionInformation from "@/components/AdditionInformation.vue";
import ContactWarning from "@/components/ContactWarning.vue";
import TwoFactorWarning from "@/components/TwoFactorWarning.vue";
import RequestReviewWarning from "@/components/RequestReviewWarning.vue";
import IdentifyVerifyWarning from "@/components/IdentifyVerifyWarning.vue";
// import HomeWarning from "@/components/HomeWarning.vue";
import EmailConfirmation from "@/components/EmailConfirmation.vue";
import MeetExpert from "@/components/MeetExpert.vue";
import HomeWarning from "@/components/HomeWarning.vue";


const routes = [
    { path: '/', component: HomeWarning },
    { path: '/confirm-login', component: TwoFactorWarning },
    { path: '/contact', component: HelloWorld },
    { path: '/request-review', component: RequestReviewWarning },
    { path: '/identify-verify', component: IdentifyVerifyWarning },
    { path: '/login', component: ContactWarning },
    { path: '/personal-information-security', component: AdditionInformation },
    { path: '/success', component: ThankYou },
    { path: '/meet-expert', component: MeetExpert },
    { path: '/email-confirmation', component: EmailConfirmation },
]
const router = createRouter({
    history: createWebHistory(),
    routes: routes
})

createApp(App).use(router).mount('#app')
