<script setup>

</script>

<template>
  <nav class="bg-white border-gray-200 dark:bg-gray-900">
    <div class="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl p-4">
      <a href="#" class="flex items-center space-x-3 rtl:space-x-reverse">
        <img src="../assets/icon-mm.png" class="h-8" alt="Flowbite Logo" />
        <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Meta</span>
      </a>
    </div>
  </nav>
  <nav class="bg-gray-50 dark:bg-gray-700">
    <div class="max-w-screen-xl px-4 py-3 mx-auto">
      <div class="flex items-center">
        <ul class="flex flex-row font-medium mt-0 space-x-8 rtl:space-x-reverse text-sm">
          <li>
            <a href="#" class="text-gray-600 dark:text-white hover:underline text-2xl" aria-current="page">Career Profile</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <section class="mt-10">
    <div class="flex flex-col items-center justify-center px-6 py-1 mx-auto lg:py-0">
      <div v-if="!isDone" class="w-full bg-white rounded-lg shadow dark:border md:mt-0 2xl:max-w-2xl xl:p-0 dark:bg-gray-800 dark:border-gray-700 text-center items-center mx-auto">
        <div class="p-6 space-y-4 md:space-y-6 sm:p-8 text-center items-center m-auto">
          <img class="w-1/3 mx-auto" src="../assets/images/active-woman-getting-likes-on-social-media.png"  alt=""/>
          <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-xl dark:text-white">
            Confirm Your Email
          </h1>
          <p>
            We sent an email to your inbox. Please click the link we sent within 24 hours to confirm your email address.
          </p>

          <a class="text-blue-500" href="#">I didn't get the email</a>
        </div>
      </div>
    </div>
    <footer class="fixed bottom-0 left-0 z-20 w-full p-4 bg-white border-t border-gray-200 shadow md:flex md:items-center md:justify-between md:p-6 dark:bg-gray-800 dark:border-gray-600">
    <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2023 <a href="#" class="hover:underline">Meta™</a>.
    </span>
    </footer>
  </section>
</template>

<style scoped>

</style>

<script>
export default {
  name: 'EmailConfirmationCompoment',
  props: {
  },
  mounted() {

  },
  data() {
    return {
      time: 0,
      isDone: false
    }
  },
  methods: {
    getWaitTime(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    goToPolicyPage() {
      window.location.href = 'https://www.facebook.com/policies_center/';
    }
  }
}
</script>